import React, { useState, useEffect, useRef  } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
// import Container from 'react-bootstrap/Container';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import axios from 'axios';
import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';


const APExtraInfo = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [accessPoints, setAccessPoints] = useState([]);
    // const [tileMaterial, setTileMaterial] = useState([]);
    // const [tileDimensions, setTileDimensions] = useState([]);
    const [accessPointTypes, setAccessPointTypes] = useState([]);
    const [extraInfo, setExtraInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const toast = useRef(null);
    const [buttonLoad, setButtonLoad] = useState(false);
    const [newExtraInfo, setNewExtraInfo] = useState(false);
    const [newAccessPointType, setNewAccessPointType] = useState({id: 0, name: ''});


    useEffect(() => {
        const fecthExtraInfo = async () => {
            try {
                setLoading(true);
                let header = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                };
                const accessPoints = await axios.get(global.config.url + `/accesspoints/${id}`, header);
                if(accessPoints.status !== 200){navigate('/login');}
                setAccessPoints(accessPoints.data);
                const responseExtraInfo = await axios.get(global.config.url + `/accesspoints/extra/${id}`, header);
                const responseTypes = await axios.get(global.config.url + `/accesspoints/extra/AccessPointType`, header);
                setAccessPointTypes(responseTypes.data);
                if(responseExtraInfo.data.length > 0) {
                    responseExtraInfo.data[0].accessPointType = '';
                    for(let i = 0; i < responseTypes.data.length; i++) {
                        if(responseTypes.data[i].id === responseExtraInfo.data[0].accessPointTypeId) {
                            responseExtraInfo.data[0].accessPointType = responseTypes.data[i].name;
                        }
                    }
                    setExtraInfo(responseExtraInfo.data[0]);
                    setNewExtraInfo(false);
                }
                else{
                    console.log(accessPoints.data)
                    let extraInfo = {
                        id: 0,
                        accessPointId: accessPoints.data.id,
                        accessPoint: accessPoints.data,
                        accessPointTypeId: 0,
                        accessPointType: null,
                        comments: '',
                        material: '',
                        size: '',
                    }
                    setExtraInfo(extraInfo);
                    setNewExtraInfo(true);
                }

                console.log(responseExtraInfo.data)
                
                setLoading(false);
                // const responseTileMaterial = await axios.get(global.config.url + `/accesspoints/extra/TileMaterial`, header);
                // setTileMaterial(responseTileMaterial.data);
                // responseExtraInfo.data[0].tileMaterial = '';
                // for(let i = 0; i < responseTileMaterial.data.length; i++) {
                //     if(responseTileMaterial.data[i].id === responseExtraInfo.data[0].tileMaterialId) {
                //         responseExtraInfo.data[0].tileMaterial = responseTileMaterial.data[i].name;
                //     }
                // }
                // const responseTileDimensions = await axios.get(global.config.url + `/accesspoints/extra/TileDimensions`, header);
                // setTileDimensions(responseTileDimensions.data);
                // console.log(responseTileDimensions.data)
                // responseExtraInfo.data[0].tileDimensions = '';
                // for(let i = 0; i < responseTileDimensions.data.length; i++) {
                //     if(responseTileDimensions.data[i].id === responseExtraInfo.data[0].tileDimensionsId) {
                //         console.log(responseTileDimensions.data[i])
                //         responseExtraInfo.data[0].tileDimensions = responseTileDimensions.data[i];
                //         console.log(responseExtraInfo.data[0].tileDimensions)
                //     }
                // }

            } catch (error) {
                setError(error);
                setLoading(false);
                console.log(error);
                // navigate('/login');
            }
        };
        fecthExtraInfo();
    }, []);

    const setMaterial = (e) => {
        setExtraInfo({...extraInfo, material: e.target.value});
    }

    const setAccessPointType = (e) => {
        console.log(e)
        var accessPointType = accessPointTypes.filter((item) => item.name === e.target.value)[0];
        console.log(accessPointType)
        setNewAccessPointType({id: accessPointType.id, name: accessPointType.name});
        
        setExtraInfo({...extraInfo, accessPointTypeId: e.target.id, accessPointType:  e.target.value});
        console.log(newAccessPointType)
    }

    const setSize= (e) => {
        setExtraInfo({...extraInfo, size: e.target.value});
    }

    const setComments = (e) => {
        setExtraInfo({...extraInfo, comments: e.target.value});
    }

    const createExtraInfo = async () => {
        try {
            let header = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            };
            var newExtraInfo = {
                id: 0,
                accessPointId: accessPoints.id,
                accessPoint: accessPoints,
                accessPointTypeId: newAccessPointType.id,
                accessPointType: {id: newAccessPointType.id, name: newAccessPointType.name},
                comments: extraInfo.comments,
                material: extraInfo.material,
                size: extraInfo.size,
            }

            console.log(newExtraInfo)
            const response = await axios.post(global.config.url + `/accesspoints/extra`, newExtraInfo, header);
            if(response.status === 201){toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Extra info created', life: 3000 });}
            else{toast.current.show({ severity: 'error', summary: 'Error', detail: 'Extra info not created', life: 3000 });}
            setButtonLoad(false);
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Extra info not created', life: 3000 });
            setButtonLoad(false);
        }
    }

    const updateExtraInfo = async () => {
        try {
            let header = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            };
            const response = await axios.put(global.config.url + `/accesspoints/extra/${extraInfo.id}`, extraInfo, header);
            if(response.status === 204){toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Extra info updated', life: 3000 });}
            else{toast.current.show({ severity: 'error', summary: 'Error', detail: 'Extra info not updated', life: 3000 });}
            setButtonLoad(false);
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Extra info not updated', life: 3000 });
            setButtonLoad(false);
        }
    }
    
    const submit = () => {
        console.log(extraInfo)
        setButtonLoad(true);
        if(newExtraInfo){
            createExtraInfo();
        }
        else{
            updateExtraInfo();
        }
    };

    // const setTileHeight = (e) => {
    //     let selectedTileDimension = tileDimensions.find(tileDimension => tileDimension.height === e.value);
    //     setExtraInfo({...extraInfo, tileDimensions: selectedTileDimension, tileDimensionsId: selectedTileDimension.id});
    // }
    if (loading) return (        
        <div className="card">
            <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
        </div>);
    if (error) return <p>Error!</p>;

    const extraInfoDialog = (
        <React.Fragment>
          <Toast ref={toast} />
          <div className="block align-content-center">
            <h4 >Extra info for tile: {accessPoints.tile}</h4>
          </div>
          <div className="block align-content-center my-5">
            <span className="flex p-float-label ">
              <Dropdown id="type" name="Type" value={extraInfo.accessPointType}
                options={accessPointTypes.map((accessPointType) => ({id: accessPointType.id, label: accessPointType.name, value: accessPointType.name }))}
                onChange={(e) => setAccessPointType(e)} className="w-full md:w-20rem"/>
              <label htmlFor="input_type">Access Point Type</label>
            </span>
            </div>
            <div className="block align-content-center my-5">
            <span className="flex p-float-label ">
                <InputText
                    id="material"
                    name="Material"
                    value={extraInfo.material}
                    onChange={(e) => setMaterial(e)}
                    className="w-full md:w-20rem"
                />
                <label htmlFor="input_material">Tile Material</label>
              {/* <Dropdown id="tilematerial" name="Material" value={extraInfo.tileMaterial}
                        options={tileMaterial.map((tileMaterial) => ({id: tileMaterial.id, label: tileMaterial.name, value: tileMaterial.name }))}
                        onChange={(e) => setMaterial(e)}/>
              <label htmlFor="input_tilematerial">Tile Material</label> */}
            </span>
            <div className="block align-content-center my-5">
            <span className="flex p-float-label ">
                        <InputText
                            id="size"
                            name="Size"
                            value={extraInfo.size}
                            onChange={(e) => setSize(e)}
                            className="w-full md:w-20rem"
                        />
                        <label htmlFor="input_size">Tile Size</label>
                        {/* <Dropdown  id="tileWidth" name="Witdth" value={extraInfo.tileDimensions.width} 
                        options={tileDimensions.map((tileDimension) => ({id: tileDimension.id, label: tileDimension.width, value: tileDimension.width }))}
                        onChange={(e) => setTileWidth(e)}/>
                        <label htmlFor="input_tileWidth">Tile Width</label> */}
                    </span>
                {/* <div className="flex align-content-center flex-wrap"> */}
                    

                    {/* <span className="flex p-float-label mx-4">
                        <Dropdown id="tileHeight" name="Height" value={extraInfo.tileDimensions.height}
                        options={tileDimensions.map((tileDimension) => ({id: tileDimension.id, label: tileDimension.height, value: tileDimension.height }))}
                        onChange={(e) => (setTileHeight(e))}/>
                        <label htmlFor="input_tileHeight">Tile Height</label>
                    </span> */}
                {/* </div> */}
            </div>
            <div className="block align-content-center my-5">
                <span className="flex p-float-label ">
                    <InputTextarea  id="comments" name="Comments" value={extraInfo.comments} className="w-full md:w-20rem" 
                    onChange={(e) => setComments(e)}/>
                    <label htmlFor="input_comments">Comments</label>
                </span>
            </div>
            <div className="card flex flex-wrap justify-content-center gap-3">
                <Button label="Submit" icon="pi pi-check" loading={buttonLoad} onClick={submit} />
            </div>
          </div>
        </React.Fragment>
      
      )
    
    return (
        <div className='container center'>
            
            <div className="card-container">
                {extraInfoDialog}
            </div>
        </div>
    );
};

export default APExtraInfo;
