import React, { useState, useEffect, useRef  } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { config } from '../config';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';
import { Messages } from 'primereact/messages';
import { Card } from 'primereact/card';
import Container from 'react-bootstrap/Container';
import {InputSwitch} from 'primereact/inputswitch';
import { useAuth } from '../contexts/AuthContext';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';

import '../config.js'

const BarcodeReaders = () => {
    const { apid } = useParams();
    const [comPorts, setComPorts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { logout } = useAuth();
    const navigate = useNavigate();
    const msgs = useRef(null);
    const toastBC = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedComPort, setSelectedComPort] = useState(null);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);


useEffect(() => {
    const fetchComPorts = async () => {
        await getComPorts();
      };
  
      fetchComPorts();
}, [apid]);

const getComPorts = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    let header = {
        headers: {
        Authorization: `Bearer ${token}`,
        },
    }

    axios.get(`${global.config.url}/accesspoints/barcodereader/${apid}`, header)
        .then(response => {
            setComPorts(response.data);
            setLoading(false);
        })
        .catch(async error => {
            if (error.response && error.response.status === 401) {
                // Logout and redirect to login screen
                await logout();
                navigate('/login');
            }
            setError(error.message);
            setLoading(false);
        })
}


const endContent = (
    <React.Fragment>
        <span className="p-input-icon-left mr-3 mt-1">
            {/* <i className="pi pi-search" /> */}
            {/* <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." /> */}
            <Button icon="pi pi-refresh" className="p-button-text" onClick={() => getComPorts()} disabled={loading} tooltip="Refresh barcode readers" tooltipOptions={{position: "bottom"}}/>
            {/* <Button icon="pi pi-trash" className="p-button-danger mx-3" rounded loading={deleteLoading} disabled={loading} onClick={() => confirm(false)} tooltip="Delete selected hub and relays" tooltipOptions={{position: "bottom"}} /> */}
            {/* <Button icon="pi pi-plus" className="p-button-success p-mr-2" rounded loading={loading} disabled={loading} onClick={handleAdd} tooltip='Add a new hub and relay'/> */}
        </span>
    </React.Fragment>
);

const textEditor = (options) => {
    console.log(options);
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
};

const numberEditor = (options) => {
    console.log(options);
    return <InputText type="number" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
};

const onRowEditComplete = (rowData) => {
    confirm(rowData);
}

const confirm = (newData) => {
      toastBC.current.show({
        severity: 'info',
        sticky: true,
        className: 'border-none',
        content: (
            <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
                <div className="text-center">
                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                    <div className="font-bold text-xl my-3">Are you sure?</div>
                </div>
                <div className="flex align-items-center">
                    <Button onClick={(e) => updateComPort(newData)} type="button" label="Yes" className="p-button-success w-6rem" />
                    <Button onClick={(e) => clear(false)} type="button" label="No" className="p-button-warning w-6rem mx-4" />
                </div>
            </div>
        )
      });
  
  };
  
  const clear = () => {
    toastBC.current.clear();
  };
  
  const updateComPort = async (newData) => {
    try{
      clear(true);
    //   if(newData.newData["lmsPort.comPort"] !== undefined){
    //     newData.newData.lmsPort.comPort = newData.newData["lmsPort.comPort"];
    //   }
    //   if(newData.newData["lmsPort.type"] !== undefined){
    //     newData.newData.lmsPort.type = newData.newData["lmsPort.type"];
    //   }
      const token = localStorage.getItem('token');
      var axiosConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      };
      const response = await axios.put(global.config.url + `/accesspoints/editbarcodereader`, newData.newData, axiosConfig);
      console.log(response);
      if(response.status === 204){
        msgs.current.show({severity: 'success', summary: '', detail: `Barcode Reader for tile ${newData.newData.accessPoint.tile} successfully updated.`});
        getComPorts();
      }
      else{
        msgs.current.show({severity: 'error', summary: '', detail: 'Error updating the barcode reader.'});
      }
    } catch (error) {
      console.error('Error updating the barcode reader Port:', error);
      if (error.response && error.response.status === 401) {
        // Logout and redirect to login screen
        await logout();
        navigate('/login');
      }
    }
  };
  


return (
    <Card className ="justify-content-md-center mx-5">
        <Container >
        <div className="card">
            <Toolbar 
                start={
                <Container className="flex align-content-center flex-wrap">
                    <Button link icon="pi pi-arrow-left" onClick={() => navigate(-1)} className='flex'/> 
                    <h4 className='flex mt-2'>Barcode Reader Ports</h4> 
                </Container>}
                end={endContent}/>
            </div>
            <Messages ref={msgs} />
            <Toast ref={toastBC} position="top-center" />
            <div className='justify-content-md-center mt-3'>
                <DataTable 
                    // ref={dt} 
                    value={comPorts}
                    // globalFilter={globalFilter}
                    dataKey="id"
                    selectionMode='single' selection={selectedComPort} onSelectionChange={(e) => setSelectedComPort(e.value)}
                    editMode='row' onRowEditComplete={onRowEditComplete}> 
                    <Column field="comPort" header="Barcode Reader COM Port" sortable editor={(options) => textEditor(options)}/>
                    <Column field="accessPoint.tile" header="Tile ID" sortable/>
                    <Column field="accessPoint.gate" header="Gate Name"></Column>
                    <Column field="accessPoint.client.name" header="Client Name"></Column>
                    <Column field="accessPoint.location.name" header="Location Name"></Column>
                    <Column field="type" header="Barcode Reader Type" sortable editor={(options) => numberEditor(options)}></Column>
                    <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                </DataTable>
            </div>
            <br></br>
            <p className="text-center">Barcode Reader type 0: Normal Nortech</p>
            <p className="text-center">Barcode Reader type 1: Check-in</p>
            <p className="text-center">Barcode Reader type 2: Legend Member number type</p>
        </Container>
        {/* <Dialog visible={displayDialog} style={{ width: '450px' }} header="HubRelay Details" modal className="p-fluid" footer={hubRelayDialogFooter} onHide={handleCancel}>
            <Toast ref={toast} />
            <div className="p-field">
                <label htmlFor="relay">Relay ID</label>

                <InputText id="relay" name="relay" value={newRelayId} onChange={(e) => setNewRelayId(e.target.value)} required autoFocus  />
                {submitted && !newRelayId && <small className="p-invalid">Relay ID is required.</small>}
            </div>
            <div className="p-field mt-3">
                <label htmlFor="hub">Hub ID</label>
                <InputText id="hub" name="hub" value={newHubId} onChange={(e) => setNewHubId(e.target.value)} required  />
                {submitted && !newHubId && <small className="p-invalid">Hub Id is required.</small>}
            </div>
            <div className="p-field mt-3">
                <p><label>Allowed</label></p>
                <InputSwitch id="allowed" name="allowed" checked={newAllowed} onChange={(e) => setNewAllowed(e.value)}/>
            </div>
        </Dialog>
        <Dialog visible={displayDialogDelete} style={{ width: '450px' }} header="Confirm" modal footer={deleteHubRelayDialogFooter} onHide={handleDeleteCancel}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                {hubRelay && <span>Are you sure you want to delete <b>{hubRelay.ajaxrelayid}</b>?</span>}
            </div>
        </Dialog> */}
    </Card>
);

}
export default BarcodeReaders;