import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import '../config.js';

const Home = () => {
  const [navbarItems, setNavbarItems] = useState([]);
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNavbarItems = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(global.config.url + '/Auth/profile', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setNavbarItems(response.data);
        if (response.status === 200) {
            console.log(response.data)
            // setNavbarItems(response.data.tableNames);`
            if(response.data.key === 'customers'){
                navigate('/customers');
            }
            else{
              navigate(`/${response.data.key}/${response.data.value}`);
            }
          }
          else{
            navigate('/login');
          }
      } catch (error) {
        // if (error.response && error.response.status === 401) {
            // Logout and redirect to login screen
            await logout();
            navigate('/login');
          // }
      }
    };

    fetchNavbarItems();
  }, []);

  return (
    <div>
      <Navbar items={navbarItems} />
      <h1>Home Page</h1>
    </div>
  );
};

export default Home;
