//get accesspoint id from the url, call the api to get the hub relays for the accesspoint id and show in a primereact datatable
import React, { useState, useEffect, useRef  } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { config } from '../config';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';
import { Messages } from 'primereact/messages';
import { Card } from 'primereact/card';
import Container from 'react-bootstrap/Container';
import {InputSwitch} from 'primereact/inputswitch';
import { useAuth } from '../contexts/AuthContext';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';

import '../config.js'

const HubRelay = () => {
    const { apid } = useParams();
    const [hubRelays, setHubRelays] = useState([]);
    const {relayStatus, setRelayStatus} = useState([]);
    const [hubRelay, setHubRelay] = useState({});
    const [displayDialog, setDisplayDialog] = useState(false);
    const [displayDialogDelete, setDisplayDialogDelete] = useState(false);
    const [newHubRelay, setNewHubRelay] = useState(false);
    const [newRelayId, setNewRelayId] = useState('');
    const [newHubId, setNewHubId] = useState('');
    const [newAllowed, setNewAllowed] = useState(true);
    const [editAllowed, setEditAllowed] = useState(true);
    const [selectedHubRelay, setSelectedHubRelay] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const toastBC = useRef(null);
    const msgs = useRef(null);
    const [loading, setLoading] = useState(true);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const dt = useRef(null);
    const navigate = useNavigate();
    const { logout } = useAuth();
    const [statuses] = useState(["true", "false"]);

    

    useEffect(() => {
        const fetchHubRelays = async () => {
            await getHubRelays();
          };
      
          fetchHubRelays();
        //   returnTable
    }
    , [apid, relayStatus]);

    const getHubRelays = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        let header = {
            headers: {
            Authorization: `Bearer ${token}`,
            },
        }

        axios.get(`${global.config.url}/ajaxrelay/ByAccessPoint/${apid}`, header)
            .then(response => {
                setHubRelays(response.data);
                setLoading(false);
            })
            .catch(async error => {
                if (error.response && error.response.status === 401) {
                    // Logout and redirect to login screen
                    await logout();
                    navigate('/login');
                }
                setError(error.message);
                setLoading(false);
            })
        axios.get(`${global.config.url}/ajaxrelay/status/${apid}`, header)
        .then(response => {
            console.log(response.data);
            setRelayStatus(response.data);
        })
        .catch(async error => {
            if (error.response && error.response.status === 401) {
                // Logout and redirect to login screen
                await logout();
                navigate('/login');
            }
            setError(error.message);
        })
    }



    const handleAdd = () => {
        setNewHubRelay(true);
        setHubRelay({});
        setSubmitted(false);
        setDisplayDialog(true);
    }

    const onRowEditComplete = (rowData) => {
        confirm(null, rowData);
    }

    const confirm = (create, newdata) => {
        if(create === null){
          toastBC.current.show({
            severity: 'info',
            sticky: true,
            className: 'border-none',
            content: (
                <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
                    <div className="text-center">
                        <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                        <div className="font-bold text-xl my-3">Are you sure?</div>
                    </div>
                    <div className="flex align-items-center">
                        <Button onClick={(e) => handleSave(newdata)} type="button" label="Confirm" className="p-button-success w-6rem" />
                        <Button onClick={(e) => handleCancel()} type="button" label="Cancel" className="p-button-warning w-6rem mx-4" />
                    </div>
                </div>
            )
          });
        }
        else{
          if(create){
            if(newRelayId === null || newRelayId  === undefined || newRelayId === ''){
              toast.current.show({severity: 'error', summary: '', detail: 'Please enter a new Relay Id.'});
              return;
            }
            if(newHubId === null || newHubId === undefined || newHubId === ''){
              toast.current.show({severity: 'error', summary: '', detail: 'Please enter a new Hub Id.'});
              return;
            }
            toastBC.current.show({
              severity: 'info',
              sticky: true,
              className: 'border-none',
              content: (
                  <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
                      <div className="text-center">
                          <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                          <div className="font-bold text-xl my-3">Are you sure?</div>
                      </div>
                      <div className="flex align-items-center">
                          <Button onClick={(e) => handleSave(null)} type="button" label="Confirm" className="p-button-success w-6rem" />
                          <Button onClick={(e) => handleCancel()} type="button" label="Cancel" className="p-button-warning w-6rem mx-4" />
                      </div>
                  </div>
              )
            });
          }
          else{
            setDeleteLoading(true);
            toastBC.current.show({
              severity: 'info',
              sticky: true,
              className: 'border-none',
              content: (
                  <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
                      <div className="text-center">
                          <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                          <div className="font-bold text-xl my-3">Are you sure?</div>
                      </div>
                      <div className="flex align-items-center">
                          <Button onClick={(e) => handleDelete()} type="button" label="Confirm" className="p-button-success w-6rem" />
                          <Button onClick={(e) => handleDeleteCancel()} type="button" label="Cancel" className="p-button-warning w-6rem mx-4" />
                      </div>
                  </div>
              )
            });
          }
        }
      
      };

    const handleSave = (newData) => {
        setSubmitted(true);
        setLoading(true);
        let token = localStorage.getItem('token');
        let header = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
          let nrid = newData === null ? newRelayId : newData.newData.ajaxRelayId;
          let nhid = newData === null ? newHubId : newData.newData.ajaxHubId;
          let id = newData === null ? 0 : newData.newData.id;

          let hubRelay = {
            "id": id,
            "ajaxRelayId": nrid,
            "ajaxHubId": nhid,
            "accessPointId": apid,
            "accessPoint": {
                "id": 0,
                "relay": "string",
                "tile": "string",
                "locationId": 0,
                "location": {
                    "id": 0,
                    "name": "string",
                    "customerId": 0,
                    "lms": 0,
                    "extra": "string",
                    "status": true
                },
                "gate": "string",
                "clientId": 0,
                "client": {
                    "id": 0,
                    "name": "string",
                    "locationId": 0,
                    "status": true,
                    "lms": 0
                }
            },
            "allowed": newAllowed
            }

        if (newData === null) {
            console.log(hubRelay);
            axios.post(`${global.config.url}/AjaxRelay`, hubRelay, header)
                .then(response => {

                    // setSuccess('HubRelay added successfully');
                    msgs.current.show({ severity: 'success', summary: '', detail: 'Hub Relay Created', life: 3000 });
                    getHubRelays();
                })
                .catch(error => {
                    msgs.current.show({ severity: 'error', summary: '', detail: 'Hub Relay Creation Failed', life: 3000 });
                    
                })
        }
        else {
            console.log(newData.newData);
            axios.put(`${global.config.url}/AjaxRelay/${hubRelay.id}`, hubRelay, header)
                .then(response => {
                    msgs.current.show({ severity: 'success', summary: '', detail: 'Hub Relay Updated', life: 3000 });
                    getHubRelays();
                })
                .catch(error => {
                    msgs.current.show({ severity: 'error', summary: '', detail: 'Hub Relay Update Failed', life: 3000 });
                })
        }
        setDisplayDialog(false);
        toastBC.current.clear();
    }

    const handleDelete = () => {
        console.log(selectedHubRelay);
        toastBC.current.clear();
        if(selectedHubRelay === null || selectedHubRelay === undefined || selectedHubRelay.id === 0){
            msgs.current.show({severity: 'error', summary: '', detail: 'Please select a hub relay to delete.'});
            setLoading(false);
            setDeleteLoading(false);
            return;
        }
        let token = localStorage.getItem('token');
        let header = {
            headers: {
            Authorization: `Bearer ${token}`,
            },
        }
        setLoading(true);
        axios.delete(`${global.config.url}/ajaxrelay/${selectedHubRelay.id}`, header)
            .then(response => {
                msgs.current.show({ severity: 'success', summary: '', detail: 'Hub Relay Deleted', life: 3000 });
                getHubRelays();
            })
            .catch(error => {
                msgs.current.show({ severity: 'error', summary: '', detail: 'Hub Relay Deletion Failed', life: 3000 });

            })
            setLoading(false);
            setDeleteLoading(false);
            toastBC.current.clear();
    }


    const handleCancel = () => {
        toastBC.current.clear();
        setLoading(false);
        setDeleteLoading(false);
        setDisplayDialog(false);
        setNewHubRelay(false);
    }

    // const handleInputChange = (event) => {
    //     const { name, value } = event.target;
    //     setHubRelay({ ...hubRelay, [name]: value });
    // }



    const handleDeleteConfirm = (rowData) => {
        setSelectedHubRelay(rowData);
        setDisplayDialogDelete(true);
    }

    const handleDeleteCancel = () => {
        setSelectedHubRelay(null);
        setDisplayDialog(false);
        toastBC.current.clear();
    }

    const endContent = (
        <React.Fragment>
            <span className="p-input-icon-left mr-3 mt-1">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                <Button icon="pi pi-refresh" className="p-button-text" onClick={() => getHubRelays()} disabled={loading} tooltip="Refresh hub and relays" tooltipOptions={{position: "bottom"}}/>
                <Button icon="pi pi-trash" className="p-button-danger mx-3" rounded loading={deleteLoading} disabled={loading} onClick={() => confirm(false)} tooltip="Delete selected hub and relays" tooltipOptions={{position: "bottom"}} />
                <Button icon="pi pi-plus" className="p-button-success p-mr-2" rounded loading={loading} disabled={loading} onClick={handleAdd} tooltip='Add a new hub and relay'/>
            </span>
        </React.Fragment>
    );

    const hubRelayDialogFooter = (
        <div>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={handleCancel} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={() => confirm(true, null)} />
        </div>
    );

    const deleteHubRelayDialogFooter = (
        <div>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={handleDeleteCancel} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={handleDelete} />
        </div>
    );

    const getSeverity = (option) => {
        switch (option) {
            case true:
                return 'success';
    
            case false:
                return 'danger';
    
            case null: 
                return 'warning';
            default:
                return null;
        }
    };

    const allowedBodyTemplate = (ajax) => {
        return <Tag value={ajax.allowed ? "Allowed" : "Not Allowed"} severity={getSeverity(ajax.allowed)}></Tag>;
    };

    const statusBodyTemplate = (relay) => {
        let value = "";
        console.log(relay.status);
        switch(relay.status){
            case true:
                value = "Online";
                break;
            case false:
                value = "Relay offline";
                break;
            case null:
                value = "Hub offline";
                break;
            default:
                value = "Unknown";
                break;
        }
        return <Tag value={value} severity={getSeverity(relay.status)}></Tag>;
    };

    const textEditor = (options) => {
        console.log(options);
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const allowedEditor = (options) => {
        // console.log(options);
        return <Dropdown
            value={options.rowData.allowed}
            options={statuses}
            onChange={(e) => options.editorCallback(e.value === 'true')}
            placeholder="Select Allowance"
            itemTemplate={(option) => {
                return <Tag value={option} severity={getSeverity(option)}></Tag>;
            }}
        />;
    };
    
    const updateRelayId = (value) => {
        // setNewRelayId(value);
        console.log(value);
    }

    const returnTable = () => {
        return (
            <DataTable 
                ref={dt} 
                value={hubRelays} selectionMode='single' selection={selectedHubRelay} onSelectionChange={(e) => setSelectedHubRelay(e.value)}
                dataKey="id"
                globalFilter={globalFilter}
                editMode='row' onRowEditComplete={onRowEditComplete}> 
                <Column field="ajaxRelayId" header="Relay ID" sortable editor={(options) => textEditor(options)}/>
                <Column field="ajaxHubId" header="Hub ID" sortable editor={(options) => textEditor(options)}/>
                <Column header="Status" body={statusBodyTemplate}></Column>
                <Column field="accessPoint.tile" header="Tile" sortable />
                <Column field="accessPoint.location.name" header="Location" sortable />
                <Column header="Allowed" body={allowedBodyTemplate}></Column>
                <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
            </DataTable>
        );
    }

    return (
        <Card className ="justify-content-md-center mx-5">
            <Container >
            <div className="card">
                <Toolbar 
                    start={
                    <Container className="flex align-content-center flex-wrap">
                        <Button link icon="pi pi-arrow-left" onClick={() => navigate(-1)} className='flex'/> 
                        <h4 className='flex mt-2'>Hubs and Relays</h4> 
                    </Container>}
                    end={endContent}/>
                </div>
                <Messages ref={msgs} />
                <Toast ref={toastBC} position="top-center" />
                <div className='justify-content-md-center mt-3'>
                    <DataTable 
                        // ref={dt} 
                        value={hubRelays} selectionMode='single' selection={selectedHubRelay} onSelectionChange={(e) => setSelectedHubRelay(e.value)}
                        dataKey="id"
                        // globalFilter={globalFilter}
                        editMode='row' onRowEditComplete={onRowEditComplete}> 
                        <Column field="ajaxRelayId" header="Relay ID" sortable editor={(options) => textEditor(options)}/>
                        <Column field="ajaxHubId" header="Hub ID" sortable editor={(options) => textEditor(options)}/>
                        <Column header="Status" body={statusBodyTemplate}></Column>
                        <Column field="accessPoint.tile" header="Tile" sortable />
                        <Column field="accessPoint.location.name" header="Location" sortable />
                        <Column header="Allowed" body={allowedBodyTemplate}></Column>
                        <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                    </DataTable>
                </div>
            </Container>
            <Dialog visible={displayDialog} style={{ width: '450px' }} header="HubRelay Details" modal className="p-fluid" footer={hubRelayDialogFooter} onHide={handleCancel}>
                <Toast ref={toast} />
                <div className="p-field">
                    <label htmlFor="relay">Relay ID</label>

                    <InputText id="relay" name="relay" value={newRelayId} onChange={(e) => setNewRelayId(e.target.value)} required autoFocus  />
                    {submitted && !newRelayId && <small className="p-invalid">Relay ID is required.</small>}
                </div>
                <div className="p-field mt-3">
                    <label htmlFor="hub">Hub ID</label>
                    <InputText id="hub" name="hub" value={newHubId} onChange={(e) => setNewHubId(e.target.value)} required  />
                    {submitted && !newHubId && <small className="p-invalid">Hub Id is required.</small>}
                </div>
                <div className="p-field mt-3">
                    <p><label>Allowed</label></p>
                    <InputSwitch id="allowed" name="allowed" checked={newAllowed} onChange={(e) => setNewAllowed(e.value)}/>
                </div>
            </Dialog>
            <Dialog visible={displayDialogDelete} style={{ width: '450px' }} header="Confirm" modal footer={deleteHubRelayDialogFooter} onHide={handleDeleteCancel}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {hubRelay && <span>Are you sure you want to delete <b>{hubRelay.ajaxrelayid}</b>?</span>}
                </div>
            </Dialog>
        </Card>
    );
}

export default HubRelay;



