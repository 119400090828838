import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useAuth } from '../contexts/AuthContext';
import Container from 'react-bootstrap/Container';
import { ScrollTop } from 'primereact/scrolltop';
import {OverlayPanel} from 'primereact/overlaypanel';
import { Tooltip } from 'primereact/tooltip';
import { ProgressBar } from 'primereact/progressbar';
import '../config.js';


const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    code: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [createVisible, setCreateVisible] = useState(false);
  const msgs = useRef(null);
  const [newCustomerName, setNewCustomerName] = useState('');
  const [newCustomerCode, setNewCustomerCode] = useState('');
  const toast = useRef(null);
  const toastBC = useRef(null);
  const dt = useRef(null);

  const op = useRef(null);

  useEffect(() => {
    const fetchCustomers = async () => {
      getCustomers();
    };

    fetchCustomers();
  }, []);


  const getCustomers = async () => {
    try {
      setLoading(true);
      setCustomers([]);
      const token = localStorage.getItem('token');
      const response = await axios.get(global.config.url + '/customers', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      setCustomers(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching customers:', error);
      if (error.response && error.response.status === 401) {
        // Logout and redirect to login screen
        await logout();
        navigate('/login');
      }
    }
  };

  const getLocations = () => {
    if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.name === null){
      msgs.current.show({severity: 'error', summary: '', detail: 'Please select a customer to view its locations'});
    }
    else{
      navigate(`/locations/${rowSelectionModel.name}/${rowSelectionModel.id}`);
    }
  };

  const getClients = () => {
    if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.name === null){
      msgs.current.show({severity: 'error', summary: '', detail: 'Please select a location to view its clients'});
    }
    else{
      navigate(`/clients/Customer/${rowSelectionModel.name}/${rowSelectionModel.id}`);
    }
  };

  const getAccessPoints = () => {
    if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.name === null){
      msgs.current.show({severity: 'error', summary: '', detail: 'Please select a customer to view its access points.'});
    }
    else{
      navigate(`/accesspoints/Customer/${rowSelectionModel.name}/${rowSelectionModel.id}`);
    }
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };

      _filters['global'].value = value;

      setFilters(_filters);
      setGlobalFilterValue(value);
  };

  const startContent = (
    <React.Fragment>
        <Button onClick={() => getLocations()} label="Get Locations" icon="pi pi-map-marker" className="mx-3" tooltip="Go to the customer's locations list"
        tooltipOptions={{position:"bottom"}}/>
        <Button onClick={() => getClients() } label="Get Clients" icon="pi pi-desktop" className="p-button-info" tooltip="Go to the customer's clients list"
        tooltipOptions={{position:"bottom"}}/>
        <Button onClick={() => getAccessPoints()} label="Get Access Points" icon="pi pi-qrcode" className="p-button-success mx-3" tooltip="Go to the customer's access points list" 
        tooltipOptions={{position:"bottom"}}/>
        <Button onClick={() => sendQRCodes()} label="Send QR Codes" icon="pi pi-qrcode" className="p-button-help" tooltip="Send QR codes to the customer's clients"
        tooltipOptions={{position:"bottom"}}/>

    </React.Fragment>
);

const footerContent = (
  <div>
      <Button label="No" icon="pi pi-times" onClick={() => setCreateVisible(false)} className="p-button-text" />
      <Button label="Yes" icon="pi pi-check" onClick={() => confirm()} autoFocus />
  </div>
);

const clear = () => {
  toastBC.current.clear();
};

const confirm = () => {
  if(newCustomerName=== undefined || newCustomerName=== ''){
    toast.current.show({severity: 'error', summary: '', detail: 'Please enter a name for the new customer.'});
    return;
  }
  if(newCustomerCode === undefined || newCustomerCode === ''){
    toast.current.show({severity: 'error', summary: '', detail: 'Please enter a code for the new customer.'});
    return;
  }
  toastBC.current.show({
      severity: 'info',
      sticky: true,
      className: 'border-none',
      content: (
          <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
              <div className="text-center">
                  <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                  <div className="font-bold text-xl my-3">Are you sure?</div>
              </div>
              <div className="flex align-items-center">
                  <Button onClick={(e) => create()} type="button" label="Confirm" className="p-button-success w-6rem" />
                  <Button onClick={(e) => clear(false)} type="button" label="Cancel" className="p-button-warning w-6rem mx-4" />
              </div>
          </div>
      )
  });
};

const sendQRCodes = async () => {
  let token = localStorage.getItem('token');
  let axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  };
  try {
    const response = await axios.get(global.config.url + `/customers/qr/${rowSelectionModel.id}`, axiosConfig);
    if(response.status === 200){
      if(response.data === true){
        msgs.current.show({severity: 'success', summary: '', detail: `QR codes sent successfully.`});
      }
      else{
        msgs.current.show({severity: 'error', summary: '', detail: `Error sending QR codes, check if the links have been submitted and try again.`});
      }
    }
    else{
      msgs.current.show({severity: 'error', summary: '', detail: `Error sending QR codes, please try again.`});
    }
  } catch (error) {
    console.error('Error fetching customers:', error);
    await logout();
    navigate('/login');
  }
};


const create = async () => {
  clear(true);
  const token = localStorage.getItem('token');
  var newCustomer = {
    name: newCustomerName,
    code: newCustomerCode,
    id: 0,
    key: ''
  }
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
    }
  };
  try {

    const response = await axios.post(global.config.url + '/customers', newCustomer, axiosConfig);
    console.log(response);
    if(response.status === 201){
      msgs.current.show({severity: 'success', summary: '', detail: `Customer ${response.data.name} successfully created.`});
      getCustomers();
      setCreateVisible(false);
      setNewCustomerCode('');
      setNewCustomerCode('');
    }
    else{
      toast.current.show({severity: 'error', summary: '', detail: 'Error creating customer, please try again.'});
    }

  } catch (error) {
    console.error('Error fetching customers:', error);
    if (error.response && error.response.status === 401) {
      // Logout and redirect to login screen
      await logout();
      navigate('/login');
    }
    else if(error.response && error.response.status === 400){
      toast.current.show({severity: 'error', summary: '', detail: 'Error creating customer, please try again.'});
    }
    else if(error.response && error.response.status === 500){
      toast.current.show({severity: 'error', summary: '', detail: 'Error creating customer, please try again.'});
    }
    else if(error.response && error.response.status === 409){
      toast.current.show({severity: 'error', summary: '', detail: error.response.data});
    }
  }

};

const endContent = (
  <React.Fragment>
    <span className="p-input-icon-right mx-2">
        <i className="pi pi-search" />
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Filter by name or code" tooltip="Start typing a customer name or code to filter" tooltipOptions={{position: "bottom"}} />
    </span>
    <Button icon="pi pi-refresh" className="p-button-text" onClick={() => getCustomers()} disabled={loading} tooltip="Refresh customers" tooltipOptions={{position: "bottom"}} />

    <Button icon="pi pi-plus" className="p-button-success ml-2 mr-3" rounded onClick={() => setCreateVisible(true)} disabled={loading} tooltip="Create new customer" tooltipOptions={{position: "bottom"}} />
    <Button type="button" icon="pi pi-file-export" rounded onClick={() => exportCSV(false)} disabled={loading} tooltip="Download as csv" tooltipOptions={{position: "bottom"}} />
  </React.Fragment>
);

  const optionsSelected = (e, rowData) =>{
    op.current.toggle(e);
    setRowSelectionModel(rowData);
  }

  const optionsTemplate = (rowData) => {
    return <Button link icon="pi pi-ellipsis-v" onClick={(e) => optionsSelected(e, rowData)} />;
  };

  const progressbar = (        
    <div className="card mt-3">
        <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
    </div>);

  return (
    <Card  className='justify-content-md-center mx-5'>
      <Container fluid="md">
        <div className="card">
          <Toolbar start={<h4>Customers</h4>} end={endContent}/>
        </div>
        <Messages ref={msgs} />
        {loading && progressbar}

        <div className='justify-content-md-center mt-5'>
          <DataTable value={customers} selectionMode={'single'} selection={rowSelectionModel} onSelectionChange={(e) => setRowSelectionModel(e.value)} 
            dataKey="id" tableStyle={{ minWidth: '50rem' }} filters={filters} filterDisplay="menu" 
            globalFilterFields={['name', 'code']} emptyMessage={loading ? "Loading customers..." : "No customers found."} ref={dt} >
              <Column field="options" header="" body={optionsTemplate}> </Column>
              <Column field="name" header="Name" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} ></Column>
              <Column field="code" header="Code" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }}></Column>
          </DataTable>
          <OverlayPanel ref={op}>
            {startContent}
          </OverlayPanel>
        </div>
        <div className="card flex justify-content-center">
          <Dialog header="Create Customer" className='justify-content-center' visible={createVisible} onHide={() => setCreateVisible(false)} 
            style={{ width: 'auto' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} footer={footerContent}>
            <Toast ref={toastBC} position="top-center" />
            <Toast ref={toast} />
            <Container className="flex align-content-center flex-wrap">
              <span className="flex p-float-label my-4">

                <InputText
                    id="name"
                    name="name"
                    value={newCustomerName}
                    onChange={(e) => setNewCustomerName(e.target.value)}
                    tooltip="Customer Name"
                />
                <label htmlFor="input_name">Name</label>
              </span>
              <span className="flex p-float-label my-4 ml-3">
                <InputText
                    id="code"
                    name="code"
                    value={newCustomerCode}
                    onChange={(e) => setNewCustomerCode(e.target.value)}
                    tooltip="Customer Code, must be unique"
                />
                <label htmlFor="input_code">Code</label>
              </span>
            </Container>
          </Dialog>
        </div>
      </Container>
      <ScrollTop />
    </Card>
    
  );
};

export default Customers;
