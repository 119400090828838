import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { Messages } from 'primereact/messages';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { useAuth } from '../contexts/AuthContext';
import Container from 'react-bootstrap/Container';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { ScrollTop } from 'primereact/scrolltop';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressBar } from 'primereact/progressbar';
import '../config.js';

const Locations = () => {
  const { customerId, customerName } = useParams();
  const [locations, setLocations] = useState([]);
  const navigate = useNavigate();
  const [rowSelectionModel, setRowSelectionModel] = useState();
  const { logout } = useAuth();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    extra: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [loading, setLoading] = useState(true);
  const msgs = useRef(null);
  const [createVisible, setCreateVisible] = useState(false);
  const [newLocationName, setNewLocationName] = useState('');
  const [newLocationExtra, setNewLocationExtra] = useState('');
  const [keypadCodes, setKeypadCodes] = useState([]);
  const toast = useRef(null);
  const toastBC = useRef(null);
  const dt = useRef(null);

  const op = useRef(null);

  useEffect(() => {
    const fetchLocations = async () => {
      getLocations();
    };

    fetchLocations();
  }, [customerId]);

  const getLocations = async () => {
    try {
      setLoading(true);
      setLocations([]);
      const token = localStorage.getItem('token');
      const response = await axios.get(global.config.url + `/locations/ByCustomer/${customerId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      setLocations(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching locations:', error);
      if (error.response && error.response.status === 401) {
        // Logout and redirect to login screen
        await logout();
        navigate('/login');
      }
    }
  };

  const getClients = () => {
    if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.name === null){
      msgs.current.show({severity: 'error', summary: '', detail: 'Please select a location to view its clients'});
    }
    else{
      navigate(`/clients/Location/${rowSelectionModel.name}/${rowSelectionModel.id}`);
    }
  };

  const getAccessPoints = () => {
    if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.name === null){
      msgs.current.show({severity: 'error', summary: '', detail: 'Please select a location to view its access points.'});
    }
    else{
      navigate(`/accesspoints/Location/${rowSelectionModel.name}/${rowSelectionModel.id}`);
    }
  };

  const getLogs = () => {
    if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.name === null){
      msgs.current.show({severity: 'error', summary: '', detail: 'Please select a location to view its logs'});
    }
    else{
      navigate(`/logs/Location/${rowSelectionModel.name}/${rowSelectionModel.id}`);
    }
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

const showKeyPadCodes = () => {
  if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.name === null){
    msgs.current.show({severity: 'error', summary: '', detail: 'Please select a location to view its keypad codes.'});
  }
  else{
    navigate(`/keypadcodes/${rowSelectionModel.id}`);
  }
};

const showReceptionCodes = () => {
  if(rowSelectionModel === undefined || rowSelectionModel.id === 0 || rowSelectionModel.name === null){
    msgs.current.show({severity: 'error', summary: '', detail: 'Please select a location to view its reception codes.'});
  }
  else{
    navigate(`/receptioncodes/${rowSelectionModel.id}`);
  }
};

  const startContent = (
    <React.Fragment>
        <Button onClick={() => getClients()} label="Get Clients"  icon="pi pi-desktop" className="" tooltip="Get clients for this location" tooltipOptions={{position:"bottom"}}/>
        <Button onClick={() => getAccessPoints()} label="Get Access Points" icon="pi pi-qrcode" className="p-button-success mx-3" tooltip="Get access points for this location" tooltipOptions={{position:"bottom"}}/>
        <Button onClick={() => getLogs()} label="Get Access Logs" icon="pi pi-file" className="p-button-warning" tooltip="Get access logs for this location" tooltipOptions={{position:"bottom"}}/>
        <Button onClick={() => sendQRCodes()} label="Send QR Codes" icon="pi pi-qrcode" className="p-button-help mx-3" tooltip="Send QR codes for this location" tooltipOptions={{position:"bottom"}}/>
        <Button onClick={() => removeStaffCode()} label="Remove Staff Code" icon="pi pi-users" className="p-button-danger" tooltip="Remove staff code for this location" tooltipOptions={{position:"bottom"}}/>
        <Button onClick={() => showKeyPadCodes()} label="Show Keypad Codes" icon="pi pi-codes" className="p-button-info mx-3" tooltip="Show keypad codes for this location" tooltipOptions={{position:"bottom"}}/>
        <Button onClick={() => showReceptionCodes()} label="Show Reception Codes" icon="pi pi-codes" className="p-button-primary" tooltip="Show reception codes for this location" tooltipOptions={{position:"bottom"}}/>
    </React.Fragment>
);

const endContent = (
  <React.Fragment>
      <span className="p-input-icon-left mx-2">
        <i className="pi pi-search" />
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Filter by name" tooltip="Start typing a location name to filter" tooltipOptions={{position: "bottom"}} />
      </span>
      <Button icon="pi pi-refresh" className="p-button-text" onClick={() => getLocations()} disabled={loading} tooltip="Refresh locations" tooltipOptions={{position: "bottom"}} />
      <Button icon="pi pi-plus" className="p-button-success mr-3 ml-2" rounded onClick={() => setCreateVisible(true)} disabled={loading} tooltip="Create new location" tooltipOptions={{position: "bottom"}} />
      {/* <Button icon="pi pi-trash" className="p-button-danger" rounded onClick={() => confirm(false)} disabled={loading} tooltip="Delete selected location" tooltipOptions={{position: "bottom"}} /> */}
      <Button type="button" icon="pi pi-file-export" className='mx-3' rounded onClick={() => exportCSV(false)} disabled={loading} tooltip="Download as csv" tooltipOptions={{position: "bottom"}} />
  </React.Fragment>
);

const footerContent = (
  <div>
      <Button label="No" icon="pi pi-times" onClick={() => setCreateVisible(false)} className="p-button-text" />
      <Button label="Yes" icon="pi pi-check" onClick={() => confirm(true)} autoFocus />
  </div>
);

const removeStaffCode = async () => {
  let token = localStorage.getItem('token');
  let axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  };
  try {
    const response = await axios.delete(global.config.url + `/locations/RemoveStaffKeypadCode/${rowSelectionModel.id}`, axiosConfig);
    if(response.status === 200){
      if(response.data === true){
        msgs.current.show({severity: 'success', summary: '', detail: `Staff code removed successfully.`});
      }
      else{
        msgs.current.show({severity: 'error', summary: '', detail: `Error removing staff code, please try again.`});
      }
    }
    else{
      msgs.current.show({severity: 'error', summary: '', detail: `Error removing staff code, please try again.`});
    }
  } catch (error) {
    console.error('Error fetching customers:', error);
    await logout();
    navigate('/login');
  }
};


const sendQRCodes = async () => {
  let token = localStorage.getItem('token');
  let axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  };
  try {
    const response = await axios.get(global.config.url + `/locations/qr/${rowSelectionModel.id}`, axiosConfig);
    if(response.status === 200){
      if(response.data === true){
        msgs.current.show({severity: 'success', summary: '', detail: `QR codes sent successfully.`});
      }
      else{
        msgs.current.show({severity: 'error', summary: '', detail: `Error sending QR codes, check if the links have been submitted and try again.`});
      }
    }
    else{
      msgs.current.show({severity: 'error', summary: '', detail: `Error sending QR codes, please try again.`});
    }
  } catch (error) {
    console.error('Error fetching customers:', error);
    await logout();
    navigate('/login');
  }
};


const clear = () => {
  toastBC.current.clear();
};

const updateLocation = async (newData) => {
  clear(true);
  const token = localStorage.getItem('token');
  let axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  };
  try {
    const response = await axios.put(global.config.url + `/locations/${newData.id}`, newData, axiosConfig);
    if(response.status === 204){
      msgs.current.show({severity: 'success', summary: '', detail: `Location ${newData.name} successfully updated.`});
      getLocations();
      setCreateVisible(false);
    }
    else{
      msgs.current.show({severity: 'error', summary: '', detail: 'Error updating location, please try again.'});
    }
  } catch (error) {
    console.error('Error fetching customers:', error);
    await logout();
    navigate('/login');
  }
};

const confirm = (create, newData) => {
  if(create === null){
    if(newData.name === undefined || newData.name === ''){
      msgs.current.show({severity: 'error', summary: '', detail: 'Please enter a name for the location.'});
      getLocations();
      return;
    }
    if(newData.extra === undefined){
      newData.extra = '';
    }
    toastBC.current.show({
      severity: 'info',
      sticky: true,
      className: 'border-none',
      content: (
          <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
              <div className="text-center">
                  <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                  <div className="font-bold text-xl my-3">Are you sure?</div>
              </div>
              <div className="flex align-items-center">
                  <Button onClick={(e) => updateLocation(newData)} type="button" label="Confirm" className="p-button-success w-6rem" />
                  <Button onClick={(e) => clear(false)} type="button" label="Cancel" className="p-button-warning w-6rem mx-4" />
              </div>
          </div>
      )
    });
  }
  else{
    if(create){
      if(newLocationName=== undefined || newLocationName=== ''){
        toast.current.show({severity: 'error', summary: '', detail: 'Please enter a name for the new location.'});
        return;
      }
      toastBC.current.show({
        severity: 'info',
        sticky: true,
        className: 'border-none',
        content: (
            <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
                <div className="text-center">
                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                    <div className="font-bold text-xl my-3">Are you sure?</div>
                </div>
                <div className="flex align-items-center">
                    <Button onClick={(e) => createLocation()} type="button" label="Confirm" className="p-button-success w-6rem" />
                    <Button onClick={(e) => clear(false)} type="button" label="Cancel" className="p-button-warning w-6rem mx-4" />
                </div>
            </div>
        )
      });
    }
    else{
      toastBC.current.show({
        severity: 'info',
        sticky: true,
        className: 'border-none',
        content: (
            <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
                <div className="text-center">
                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                    <div className="font-bold text-xl my-3">Are you sure?</div>
                </div>
                <div className="flex align-items-center">
                    <Button onClick={(e) => deleteLocation()} type="button" label="Confirm" className="p-button-success w-6rem" />
                    <Button onClick={(e) => clear(false)} type="button" label="Cancel" className="p-button-warning w-6rem mx-4" />
                </div>
            </div>
        )
      });
    }
  }

};

const createLocation = async () => {
  clear(true);
  const token = localStorage.getItem('token');
  var newLocation = {
    name: newLocationName,
    extra: newLocationExtra,
    id: 0,
    lms: 0,
    customerId: customerId,
    status: false,
  }
  let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
    }
  };
  try {

    const response = await axios.post(global.config.url + '/locations', newLocation, axiosConfig);
    console.log(response);
    if(response.status === 201){
      msgs.current.show({severity: 'success', summary: '', detail: `Location ${response.data.name} successfully created.`});
      getLocations();
      setCreateVisible(false);
      setNewLocationName('');
      setNewLocationExtra('');
    }
    else{
      toast.current.show({severity: 'error', summary: '', detail: 'Error creating location, please try again.'});
    }

  } catch (error) {
    console.error('Error fetching customers:', error);
    if (error.response && error.response.status === 401) {
      // Logout and redirect to login screen
      await logout();
      navigate('/login');
    }
    else if(error.response && error.response.status === 400){
      toast.current.show({severity: 'error', summary: '', detail: 'Error creating location, please try again.'});
    }
    else if(error.response && error.response.status === 500){
      toast.current.show({severity: 'error', summary: '', detail: 'Error creating location, please try again.'});
    }
    else if(error.response && error.response.status === 409){
      toast.current.show({severity: 'error', summary: '', detail: error.response.data});
    }
  }

};

const deleteLocation = async () => {
  clear(true);
  const token = localStorage.getItem('token');
  if(rowSelectionModel === undefined || rowSelectionModel === 0){
    msgs.current.show({severity: 'error', summary: '', detail: 'Please select a location to delete.'});
    return;
  }
  try {
    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
      }
    };
    const response = await axios.delete(global.config.url + `/locations/${rowSelectionModel}`, axiosConfig);
    console.log(response);
    if(response.status === 204){
      msgs.current.show({severity: 'success', summary: '', detail: `Location deleted successfully.`});
      getLocations();
      setCreateVisible(false);
    }
    else{
      toast.current.show({severity: 'error', summary: '', detail: 'Error deleting location, please try again.'});
    }

  } catch (error) {
    console.error('Error fetching customers:', error);
    if (error.response && error.response.status === 401) {
      // Logout and redirect to login screen
      await logout();
      navigate('/login');
    }
    else if(error.response && error.response.status === 400){
      toast.current.show({severity: 'error', summary: '', detail: 'Error deleting location, please try again.'});
    }
    else if(error.response && error.response.status === 500){
      toast.current.show({severity: 'error', summary: '', detail: 'Error deleting location, please try again.'});
    }
  }
  
};

  const optionsSelected = (e, rowData) =>{
    op.current.toggle(e);
    setRowSelectionModel(rowData);
  }

  const locationOptiosTemplate = (rowData) => {
    return <Button link icon="pi pi-ellipsis-v" onClick={(e) => optionsSelected(e, rowData)} />;
  };
  const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
  };

  const onRowEditComplete = async (e) => {
    let {newData, index} = e;
    let lcs = [...locations];
    lcs[index] = newData;
    setLocations(lcs);
    confirm(null, newData);
  };

  const progressbar = (        
    <div className="card mt-3">
        <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
    </div>);

  return (
    <Card className='justify-content-md-center mx-5'>
      <Container fluid="md">

        <div className="card">
          <Toolbar 
          start={
          <Container className="flex align-content-center flex-wrap">
            <Button link icon="pi pi-arrow-left" onClick={() => navigate(-1)} className='flex'/> 
            <h4 className='flex mt-2'>Locations for customer '{customerName}'</h4> 
          </Container>} 
          end={endContent}/>
        </div>
        <Messages ref={msgs} />
        <Toast ref={toastBC} position="top-center" />
        <div className='justify-content-md-center mt-3'>
        {loading && progressbar}

          <DataTable value={locations} selectionMode={'single'} selection={rowSelectionModel} onSelectionChange={(e) => setRowSelectionModel(e.value)} 
            dataKey="id" tableStyle={{ minWidth: '50rem' }} filters={filters} stripedRows
            globalFilterFields={['name', 'extra']} emptyMessage={loading ? "Loading locations..." : "No locations found."} ref={dt}
            editMode='row' onRowEditComplete={onRowEditComplete}>
              {/* <Column selectionMode="single" headerStyle={{ width: '2rem' }}></Column> */}
              <Column field="options" header="" body={locationOptiosTemplate}> </Column>
              <Column field="name" header="Name" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} editor={(options) => textEditor(options)}></Column>
              <Column field="extra" header="Extra" editor={(options) => textEditor(options)}></Column>
              <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>

          </DataTable>
          <OverlayPanel ref={op}>
            {startContent}
          </OverlayPanel>
        </div>

        <div className="card flex justify-content-center">
          <Dialog header="Create Location" className='justify-content-center' visible={createVisible} onHide={() => setCreateVisible(false)} 
            style={{ width: 'auto' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} footer={footerContent}>

            <Toast ref={toast} />
            <Container className="flex align-content-center flex-wrap">
              <span className="flex p-float-label my-4">

                <InputText
                    id="name"
                    name="name"
                    value={newLocationName}
                    onChange={(e) => setNewLocationName(e.target.value)}
                />
                <label htmlFor="input_name">Name</label>
              </span>
              <span className="flex p-float-label my-4 ml-3">
                <InputText
                    id="extra"
                    name="extra"
                    value={newLocationExtra}
                    onChange={(e) => setNewLocationExtra(e.target.value)}

                />
                <label htmlFor="input_extra">Extra</label>
              </span>
            </Container>
          </Dialog>
        </div>
      </Container>
      <ScrollTop />
      </Card>

  );
};

export default Locations;
