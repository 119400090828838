import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = ({ items }) => {
  return (
    <nav>
      {Array.isArray(items) &&
        items.map((item, index) => (
          <Link
            key={index}
            to={`/${item.toLowerCase().replace(' ', '-')}`}
          >
            {item}
          </Link>
          
        ))}
    </nav>
  );
};

export default Navbar;
