import React, { useState, useEffect, useRef  } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { config } from '../config';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';
import { Messages } from 'primereact/messages';
import { Card } from 'primereact/card';
import Container from 'react-bootstrap/Container';
import {InputSwitch} from 'primereact/inputswitch';
import { useAuth } from '../contexts/AuthContext';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';

import '../config.js'

const KeypadCodes = () => {
    const { locid } = useParams();
    const [keypadCodes, setKeypadCodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { logout } = useAuth();
    const navigate = useNavigate();
    const msgs = useRef(null);
    const toastBC = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedKeyPad, setSelectedKeyPad] = useState(null);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);


useEffect(() => {
    const fetchKeyPadCodes = async () => {
        await getKeyPadCodes();
      };
  
      fetchKeyPadCodes();
}, [locid]);

const getKeyPadCodes = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    let header = {
        headers: {
        Authorization: `Bearer ${token}`,
        },
    }

    axios.get(`${global.config.url}/locations/keypadcode/${locid}`, header)
        .then(response => {
            setKeypadCodes(response.data);
            setLoading(false);
        })
        .catch(async error => {
            if (error.response && error.response.status === 401) {
                // Logout and redirect to login screen
                await logout();
                navigate('/login');
            }
            setError(error.message);
            setLoading(false);
        })
}

const handleDelete = () => {
    console.log(selectedKeyPad);
    toastBC.current.clear();
    if(selectedKeyPad === null || selectedKeyPad === undefined || selectedKeyPad.id === 0){
        msgs.current.show({severity: 'error', summary: '', detail: 'Please select a KeyPad code to delete.'});
        setLoading(false);
        setDeleteLoading(false);
        return;
    }
    let token = localStorage.getItem('token');
    let header = {
        headers: {
        Authorization: `Bearer ${token}`,
        },
    }
    setLoading(true);
    axios.delete(`${global.config.url}/locations/keypadcode/${selectedKeyPad.id}`, header)
        .then(response => {
            msgs.current.show({ severity: 'success', summary: '', detail: 'HKeyPad code Deleted', life: 3000 });
            getKeyPadCodes();
        })
        .catch(error => {
            msgs.current.show({ severity: 'error', summary: '', detail: 'keyPad code Deletion Failed', life: 3000 });

        })
        setLoading(false);
        setDeleteLoading(false);
        toastBC.current.clear();
}

const handleDeleteCancel = () => {
    setSelectedKeyPad(null);
    setDisplayDialog(false);
    toastBC.current.clear();
}

const confirm = () => {
    setDeleteLoading(true);
    toastBC.current.show({
        severity: 'info',
        sticky: true,
        className: 'border-none',
        content: (
            <div className="flex flex-column align-items-center" style={{ flex: '1' }}>
                <div className="text-center">
                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
                    <div className="font-bold text-xl my-3">Are you sure?</div>
                </div>
                <div className="flex align-items-center">
                    <Button onClick={(e) => handleDelete()} type="button" label="Confirm" className="p-button-success w-6rem" />
                    <Button onClick={(e) => handleDeleteCancel()} type="button" label="Cancel" className="p-button-warning w-6rem mx-4" />
                </div>
            </div>
        )
    });
  
  };

const endContent = (
    <React.Fragment>
        <span className="p-input-icon-left mr-3 mt-1">
            {/* <i className="pi pi-search" /> */}
            {/* <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." /> */}
            <Button icon="pi pi-refresh" className="p-button-text" onClick={() => getKeyPadCodes()} disabled={loading} tooltip="Refresh KeyPad Codes" tooltipOptions={{position: "bottom"}}/>
            {/* <Button icon="pi pi-trash" className="p-button-danger mx-3" rounded loading={deleteLoading} disabled={loading} onClick={() => confirm(false)} tooltip="Delete selected hub and relays" tooltipOptions={{position: "bottom"}} /> */}
            {/* <Button icon="pi pi-plus" className="p-button-success p-mr-2" rounded loading={loading} disabled={loading} onClick={handleAdd} tooltip='Add a new hub and relay'/> */}
        </span>
    </React.Fragment>
);

const textEditor = (options) => {
    console.log(options);
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
};

const transformCreatedDate = (date) => {
    let d = new Date(date.created).toLocaleString("en-GB")
    return d;
};

const transformExpiryDate = (date) => {
    let d = new Date(date.expiry).toLocaleString('en-GB', { timeZone: 'Europe/London' });
    return d;
};

const getSeverity = (option) => {
    switch (option) {
        case true:
            return 'success';

        case false:
            return 'warning';
        default:
            return null;
    }
};

const statusBodyTemplate = (keypadcode) => {
    let value = "";
    switch(keypadcode.isStaff){
        case true:
            value = "Staff";
            break;
        case false:
            value = "Not Staff";
            break;
        default:
            value = "Unknown";
            break;
    }
    return <Tag value={value} severity={getSeverity(keypadcode.isStaff)}></Tag>;
};


return (
    <Card className ="justify-content-md-center mx-5">
        <Container >
        <div className="card">
            <Toolbar 
                start={
                <Container className="flex align-content-center flex-wrap">
                    <Button link icon="pi pi-arrow-left" onClick={() => navigate(-1)} className='flex'/> 
                    <h4 className='flex mt-2'>KeyPad Codes</h4> 
                </Container>}
                end={endContent}/>
            </div>
            <Messages ref={msgs} />
            <Toast ref={toastBC} position="top-center" />
            <div className='justify-content-md-center mt-3'>
                <DataTable 
                    // ref={dt} 
                    value={keypadCodes}
                    // globalFilter={globalFilter}
                    dataKey="id"
                    selectionMode='single' selection={selectedKeyPad} onSelectionChange={(e) => setSelectedKeyPad(e.value)}> 
                    <Column field="code" header="Code" sortable editor={(options) => textEditor(options)}/>
                    <Column field="created" header="Created at" body={transformCreatedDate}/>
                    <Column field="expiry" header="Expires at" body={transformExpiryDate}/>
                    <Column field="isStaff" header="Is Staff" body={statusBodyTemplate}></Column>
                    <Column field="index" header="Index" sortable editor={(options) => textEditor(options)}></Column>
                    {/* <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column> */}
                </DataTable>
            </div>
        </Container>
        {/* <Dialog visible={displayDialog} style={{ width: '450px' }} header="HubRelay Details" modal className="p-fluid" footer={hubRelayDialogFooter} onHide={handleCancel}>
            <Toast ref={toast} />
            <div className="p-field">
                <label htmlFor="relay">Relay ID</label>

                <InputText id="relay" name="relay" value={newRelayId} onChange={(e) => setNewRelayId(e.target.value)} required autoFocus  />
                {submitted && !newRelayId && <small className="p-invalid">Relay ID is required.</small>}
            </div>
            <div className="p-field mt-3">
                <label htmlFor="hub">Hub ID</label>
                <InputText id="hub" name="hub" value={newHubId} onChange={(e) => setNewHubId(e.target.value)} required  />
                {submitted && !newHubId && <small className="p-invalid">Hub Id is required.</small>}
            </div>
            <div className="p-field mt-3">
                <p><label>Allowed</label></p>
                <InputSwitch id="allowed" name="allowed" checked={newAllowed} onChange={(e) => setNewAllowed(e.value)}/>
            </div>
        </Dialog>
        <Dialog visible={displayDialogDelete} style={{ width: '450px' }} header="Confirm" modal footer={deleteHubRelayDialogFooter} onHide={handleDeleteCancel}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                {hubRelay && <span>Are you sure you want to delete <b>{hubRelay.ajaxrelayid}</b>?</span>}
            </div>
        </Dialog> */}
    </Card>
);

}
export default KeypadCodes;