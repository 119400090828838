import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { useAuth } from '../contexts/AuthContext';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Paginator } from 'primereact/paginator';
import { Calendar } from 'primereact/calendar';
import { ScrollTop } from 'primereact/scrolltop';
import { Toolbar } from 'primereact/toolbar';
import { ProgressBar } from 'primereact/progressbar';
import '../config.js'
        

const EventRecords = () => {
  const { type, id, typename } = useParams();
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const { logout } = useAuth();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    membership: { value: null, matchMode: FilterMatchMode.CONTAINS },
    scanType: { value: null, matchMode: FilterMatchMode.CONTAINS},
    time: { value: null, matchMode: FilterMatchMode.CONTAINS},
    'accessPoint.client.name': { value: null, matchMode: FilterMatchMode.CONTAINS},
    'accessPoint.gate': { value: null, matchMode: FilterMatchMode.CONTAINS},
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const fetchEvents = async () => {
        getEvents(rows, 0);
    };

    fetchEvents();
  }, [id]);

  const getEvents = async (rows, page) => {
    setLoading(true);
    setEvents([]);
    const dt = new Date(date + 'Z').toISOString().split('T')[0];
    const token = localStorage.getItem('token');
    try {
        // const url = firstid ? global.config.url + `/eventrecord/By${type}/${id}?lastId=${lastRecord}&pageSize=${rows}&initDate=${dt}&endDate=${dt}` 
        // : global.config.url + `/eventrecord/By${type}/${id}?firstId=${firstRecord}&pageSize=${rows}&initDate=${dt}&endDate=${dt}`;
        const url = global.config.url + `/eventrecord/By${type}/${id}?page=${page}&pageSize=${rows}&initDate=${dt}&endDate=${dt}`;
        const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setEvents(response.data);
          for(let i = 0; i < response.data.length; i++){
            let date = new Date(response.data[i].time + 'Z');
            response.data[i].time = date.toLocaleString("en-GB");
          }
          setLoading(false);
          let total = 0;
          if(response.data.length == rows){
            total = (page + 1) * rows +1;
          } else {
            total = page * rows + response.data.length;
          }
          setTotalRecords(total);
      } catch (error) {
        if (error.response && error.response.status === 401) {
            await logout();
            navigate('/login');
          }
          setLoading(false);
          setTotalRecords(0);
          setEvents([]);
      }
      // const urlCount = global.config.url + `/eventrecord/By${type}Count/${id}?initDate=${dt}&endDate=${dt}`;
      // const responseCount = await axios.get(urlCount, {
      //     headers: {
      //     Authorization: `Bearer ${token}`,
      //     },
      // });
      // setTotalRecords(responseCount.data);
    };
  const onPageChange = (event) => {
    setLoading(true);
    setRows(event.rows);
    getEvents(event.rows, event.page);
    setFirst(event.first);
  };

  const getByDate = () =>{
    // console.log('date ISO:', date.replace(/-/g, '\/').replace(/T.+/, ''))
    setLoading(true);
    setFirst(0);
    getEvents(rows, 0)
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const progressbar = (        
    <div className="card mt-3">
        <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
    </div>);

  return (
    <Card className='justify-content-md-center mt-5'>
    <Container fluid="md" >
    <div className="panel">
      <Toolbar           
      start={
        <Container className="flex align-content-center flex-wrap">
          <Button link icon="pi pi-arrow-left" onClick={() => navigate(-1)} className='flex'/> 
          <h4 className='flex mt-2'>Events for '{typename}'</h4> 
        </Container>} 
        end = {        
        <div className="flex justify-content-end">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Filter Search" tooltip="Start typing to filter" 
              tooltipOptions={{position: "bottom"}}/>
          </span>
        </div>}/>
    </div>
    {loading && progressbar}
    <Row className='justify-content-md-center mt-5'>
      <Col sm={8}>
        <div className="card flex justify-content-center">
            <Calendar value={date} onChange={(e) => setDate(e.value)} dateFormat="dd/mm/yy" showIcon  showButtonBar />
        </div>
      </Col>
      <Col sm={4}>
        <div className="card flex justify-content-center">
            <Button label="Get" className="p-button-raised" onClick={getByDate} />
        </div>
      </Col>
    </Row>
    {/* <Row className='justify-content-md-center'>
        <div className="card">
            <Paginator first={first} rows={rows} totalRecords={1000} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
        </div>
    </Row> */}

    <Row className='justify-content-md-center mt-5'>
        <Col>
        <DataTable value={events} selectionMode={'radiobutton'} selection={rowSelectionModel} onSelectionChange={(e) => setRowSelectionModel(e.value.id)} 
        dataKey="id" tableStyle={{ minWidth: '50rem' }} filters={filters} filterDisplay="menu" 
        globalFilterFields={['membership', 'time', 'accessPoint.gate', 'scanType', 'accessPoint.client.name']} emptyMessage={loading ? "Loading events..." : "No events found."}>
            <Column field="membership" header="Membership" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} ></Column>
            <Column field="time" header="Time"></Column>
            <Column field="accessPoint.gate" header="Access Point"></Column>
            <Column field="scanType" header="Scan Type"></Column>
            <Column field="accessPoint.client.name" header="Client"></Column>
        </DataTable>
        </Col>

    </Row>

    <Row className='justify-content-md-center mt-5'>
        <div className="card">
            <Paginator first={first} rows={rows} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 30]} onPageChange={onPageChange} />
        </div>
    </Row>

    </Container>
    <ScrollTop />
    </Card>
  );
};

export default EventRecords;
