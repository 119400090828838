import React, { useState, useEffect, useRef  } from 'react';
import axios from 'axios';
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import {Form } from 'react-bootstrap';
import { RepeatOneSharp } from '@mui/icons-material';

const ResetPassword = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [loading, setLoading] = useState(false);
    const msgs = useRef(null);
    const [searchParams] = useSearchParams();
    const [success, setSuccess] = useState(false);
    const [seconds, setSeconds] = useState(5);
    const [code, setCode] = useState("");
    const [type, setType] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        let c = searchParams.get('code');
        let e = searchParams.get('email');
        let t = searchParams.get('type');
        if (!c) {
            navigate('/login');
        }
        else if(!e){
            navigate('/login');
        }
        else if(!t){
            navigate('/login');
        }
        else{
            setCode(c);
            setEmail(e);
            setType(t);
        }
    }, [code, email, type]);

    function delay(milliseconds){
        return new Promise(resolve => {
            setTimeout(resolve, milliseconds);
        });
    }

    const handleSubmit = async (e) => {
        msgs.current.clear();
        e.preventDefault();

        if (password !== passwordRepeat) {
            return msgs.current.show({severity: 'error', summary: '', detail: 'Passwords do not match'});
        }

        try {
            setLoading(true);
            console.log(code); 
            const token = localStorage.getItem('token');
            let body ={
                "email": email,
                "code": code,
                "password": password
              }
              let axiosConfig = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
              };
            try {
                const response = await axios.post(global.config.url + '/auth/reset-password', body, axiosConfig);
                if(response.status === 200){
                    msgs.current.show({severity: 'success', summary: '', detail: 'Password reseted successfully', sticky: true});
                    setSuccess(true);
                    for(let i = 5; i >= 1; i--){
                        setSeconds(i);
                        await delay(1000);
                        if(i === 1){
                            navigate('/login');
                        }
                    }
                }
                else{
                    msgs.current.show({severity: 'error', summary: '', detail: 'Failed to reset password'});
                }
                // navigate('/login');
            } catch (err) {
                if(err.response.status === 400){
                    console.log(err.response.data)
                    if(err.response.data.errors){
                        if(err.response.data.errors.Password.length > 0){
                            for(let i = 0; i < err.response.data.errors.Password.length; i++){
                                msgs.current.show({severity: 'error', summary: '', detail: err.response.data.errors.Password[i], sticky: true});
                            }
                        }
                    }
                    else{
                        if(err.response.data[""].length > 0){
                            for(let i = 0; i < err.response.data[""].length; i++){
                                msgs.current.show({severity: 'error', summary: '', detail: err.response.data[""][i], sticky: true});
                            }
                        }
                    }

                    // else{
                    //     msgs.current.show({severity: 'error', summary: '', detail: 'Failed to reset password'});
                    // }
                }
                else{
                    msgs.current.show({severity: 'error', summary: '', detail: 'Failed to reset password'});
                }
                console.log(err.response.data.errors);
            }
            
        } catch (er) {
            console.log(er);
            msgs.current.show({severity: 'error', summary: '', detail: 'Failed to reset password'});
        }

        setLoading(false);
    };

    return (
        <div className="container center">
            <div className="p-d-flex p-flex-column p-jc-center p-ai-center">
                <Messages ref={msgs} />
                {success ? (
                    <div className="w-100 mt-2">
                        <p className="text-center">Redirecting to login in {seconds} seconds...</p>
                        <Link to="/login">Cannot wait {seconds} seconds, take me to login now</Link>
                    </div>
                )
                : (
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control type="password" placeholder="New Password" value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicRepeatPassword">
                        <Form.Label>Repeat New Password</Form.Label>
                        <Form.Control type="password" placeholder="Repeat New Password" value={passwordRepeat}
                            onChange={(e) => setPasswordRepeat(e.target.value)}
                            required />
                    </Form.Group>
                    {/* {error && <p>{error}</p>} */}
                    { type === "forgot" ? <Button type="submit" label="Reset Password" disabled={loading} loading={loading} /> : <Button type="submit" label="Set New Password" disabled={loading} loading={loading} /> }
                    <div className="w-100 mt-2">
                        <Link to="/login">Go to login?</Link>
                    </div>
                </Form>)}
            </div>
        </div>
    );
};

export default ResetPassword;