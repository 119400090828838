import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import Form from 'react-bootstrap/Form';
import '../config.js'
import { Messages } from 'primereact/messages';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const msgs = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(global.config.url + '/Auth/Login', {
        email,
        password,
      });

      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token.result);
        navigate('/');
      } else {
        // setError('Invalid login attempt.');
        msgs.current.show({severity: 'error', summary: '', detail: 'Email and password do not match'});
      }
    } catch (err) {
      // setError('Invalid login attempt.');
      msgs.current.show({severity: 'error', summary: '', detail: 'Invalid login attempt please refresh and try again later'});
    }
  };

  return (
    //Have login form within a box with shadow borders and have the box in the center of the screen
    <div >
      <div className='container center'>
        <div className="p-d-flex p-flex-column p-jc-center p-ai-center">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email"  value={email}
                  onChange={(e) => setEmail(e.target.value)} required/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" value={password}
                onChange={(e) => setPassword(e.target.value)}
                required />
            </Form.Group>
            {/* {error && <p>{error}</p>} */}
            <Messages ref={msgs} />
            <div className="w-100 mt-2">
              <Link to="/forgot-password">Forgot password?</Link>
            </div>
              <Button type="submit" label="Login" className='mt-4' />

          </Form>
        </div>
      </div>

    </div>
  );
};

export default Login;
