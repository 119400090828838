import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import {Form } from 'react-bootstrap';
import { ScrollTop } from 'primereact/scrolltop';

const ForgotPassword = () => {

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const msgs = useRef(null);
    
    const navigate = useNavigate();

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setLoading(true);
            let body ={
                "email": email,
                "callbackUrl": window.location.origin + "/reset-password"
              }
            try {
                await axios.post(global.config.url + '/auth/forgot-password', body);
                // navigate('/login');
                msgs.current.show({severity: 'success', summary: '', detail: 'Email sent successfully, please check your inbox'});
            } catch (err) {
                console.log(err);
            }
            
        } catch {
            msgs.current.show({severity: 'error', summary: '', detail: 'Failed to reset password'});
        }

        setLoading(false);
    }

    return (
        <div className="container center">
            <div className="p-d-flex p-flex-column p-jc-center p-ai-center">
                <Messages ref={msgs} />
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </Form.Group>
                    <Button disabled={loading} className="w-100 mt-4" type="submit" loading={loading}>
                        Ask Password Reset
                    </Button>
                </Form>
                <div className="w-100 mt-2">
                    <Link to="/login">Go to Login</Link>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;

